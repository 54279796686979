import { Outlet } from '@remix-run/react';
import { MainFooter } from '../footers/main/MainFooter';
import { MainHeader } from '../headers/main/MainHeader';
import { BannerSlider } from '../banners/BannerSlider';
import {
  Container,
  Content,
  FooterContainer,
  HeaderContainer,
  StickyContainer,
} from './Common';
import type { IUserInfo } from 'app/models/auth';

export interface IPublicProps {
  isAuth: boolean;
  headerProps: IUserInfo;
  showBanner?: boolean;
}

const PublicLayoutBase = ({
  isAuth,
  headerProps,
  showBanner = false,
  ...rest
}: IPublicProps) => {
  return (
    <>
      <StickyContainer>
        {showBanner && <BannerSlider isAuth={isAuth} />}
        <HeaderContainer>
          <MainHeader {...headerProps} />
        </HeaderContainer>
      </StickyContainer>
      <Container role="application" {...rest}>
        <Content>
          <Outlet />
        </Content>
      </Container>
      <FooterContainer>
        <MainFooter isAuth={isAuth} />
      </FooterContainer>
    </>
  );
};

export { PublicLayoutBase };
